.mainOuterDiv {
  background-color: #00000029;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.MainInnerDiv {
  /* display: flex; */
  width: 428px;
  height: 100vh;
  /* background-color: white; */
  background-image: url("../../../../../public/Images/Home/background.png");
  overflow: scroll;
  scroll-behavior: none;
  /* align-items: center; */
  /* justify-content: center; */
}

.homeIcon {
  height: 35px;
  display: block;
  float: left;
  top: 25px;
  left: 25px;
  position: absolute;
  cursor: pointer;
}

.homeBtnDiv {
  position: relative;
}

.profileImg {
  width: 160px;
  height: 210px;
  border-radius: 10px;
  border: white 1px solid;
}

.profileImgDiv {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 50px 0px 0px 0px;
}
.signUpDiv {
  display: flex;
  width: 78%;
  align-items: center;
  margin: 5px 0px;
  /* margin: 5px 35px 10px 35px; */
}
.signUp {
  width: 36%;
  font-weight: bold;
  text-align: center;
}
.signUpDate {
  text-align: center;
  font-weight: bold;
  width: 55%;
  color: white;
}
.userNameProAdminDiv {
  display: flex;
  align-items: center;
  margin: 8px 0px;
  position: relative;
  justify-content: center;
}
.UserName {
  font-weight: bold;
  overflow-wrap: anywhere;
}
.proDiv {
  margin-left: 7px;
}
.adminDiv {
  margin-left: 7px;
}
.proUser {
  background-color: black;
  color: white;
}
.adminUser {
  background-color: #00f5f5;
}
.proUser,
.adminUser {
  font-size: 12px;
  padding: 2px 15px;
  border-radius: 15px;
}
.userDeepDetails {
  background-color: #3c8578;
  border: 1px solid #3c8578;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  /* width: 90%; */
  /* padding: 0px 100px; */
  margin: 5px 35px 10px 35px;
}

.userDetailsInner {
  margin: 15px 0px;
  width: 85%;
}

.fandlNamediv,
.notesandDashboardDiv,
.actionDiv,
.proAdminDiv {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.deleteDiv {
  display: flex;
  justify-content: center;
  align-items: center;
}

.fname,
.lname {
  width: 48%;
  background-color: white !important;
  border-radius: 10px;
  border-right: 0px !important;
  outline: none !important;
  padding: 0px 5px 0px 10px !important;
  color: black;
  font-weight: bold;
  font-size: 15px;
}

.golabalEditing {
  background-color: white;
  padding: 10px 5px 10px 10px;
  border: 1px solid #707070;
  border-radius: 12px;
  color: black;
  font-weight: bold;
  font-size: 15px;
  margin: 5px 0px;
}

.viewrsNumber {
  color: #3c8578;
}

.notes {
  background-color: #f5ec00 !important;
  /* padding: 10px 2px; */
  width: 45% !important;
  /* color: black; */
  font-weight: bold !important;
  border-radius: 12px !important;
}

.notes:hover {
  background-color: #f5ec00 !important;
}

.lastDiv {
  display: flex;
  flex-direction: row;
}

.dashboard {
  width: 45%;
}

.delete,
.suspend,
.pro,
.admin {
  width: 45%;
}
.deleteUser {
  width: 45%;
  background-color: #fd0000;
  color: white;
}

.MessageDiv {
  justify-content: center;
  align-items: center;
  display: flex;
}

.Message {
  background-color: #00f568;
  color: white;
  width: 45%;
  padding: 8px 10px;
  /* margin-bottom: 10px; */
  /* border: 1px solid #707070;
    border-radius: 10px;
    color: black;
    font-weight: bold;
    font-size: 15px;
    margin: 5px 0px;
    width: 30%; */
}

.delete {
  background-color: #9e00ac;
  color: white;
}

.suspend {
  background-color: #fd6b00;
  color: white;
}
.pro {
  background-color: black;
  color: white;
}
.admin {
  background-color: transparent;
  border: 1px solid #00f5f5;
  color: #00f5f5;
}

.maildiv,
.numberdiv,
.pageViewdiv,
.genderDiv,
.DOBdiv,
.nameDiv,
.businessViewDiv {
  text-align: left;
  margin-left: 10px;
  margin: 10px 0px;
  min-height: 40px;
}
.firstlastNameDiv {
  width: 48%;
}
.fNameDiv,
.lNameDiv {
  text-align: left;
  margin-left: 10px;
  margin: 10px 0px;
  min-height: 40px;
}
.nameHalf {
  width: 50%;
}

.suspendedMessage,
.activeMessage {
  color: red;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  margin-bottom: 5px;
}

.activeMessage {
  color: #3c8578;
}

.unsuspend {
  background-color: #000000;
  color: white;
}

.goBackIcon {
  height: 35px;
  display: block;
  float: left;
  font-weight: bold;
  margin-left: 26px;
  top: 75px;
  /* left: 25px; */
  color: rgb(94, 85, 85);
  position: absolute;
  cursor: pointer;
}

@media only screen and (max-width: 400px) and (min-width: 375px) {
  .notes {
    background-color: #f5ec00 !important;
    /* padding: 10px 2px; */
    width: 40% !important;
    /* color: black; */
    font-size: 13.5px !important;
    font-weight: bold !important;
  }

  .suspend {
    font-size: 13.5px !important;
  }
}

/* ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */

@media only screen and (max-width: 375px) and (min-width: 320px) {
  .notes {
    background-color: #f5ec00 !important;
    /* padding: 10px 2px; */
    width: 45% !important;
    /* color: black; */
    font-size: 13.5px !important;
    font-weight: bold !important;
  }

  .suspend {
    font-size: 13.5px !important;
  }

  .fname,
  .lname {
    width: 48%;
  }
  .dashboard {
    width: 45%;
    font-size: 13px;
  }

  .golabalEditing {
    padding: 8px 5px;
  }

  .delete,
  .suspend {
    width: 45%;
    font-size: 13px;
  }

  .userDeepDetails {
    margin: 0px 25px 10px 25px;
  }

  .unsuspend {
    font-size: 12px;
    width: 48%;
  }

  .UserName {
    margin: 5px 25px;
  }
}
